<mat-sidenav-container class="w-screen h-screen" autosize>
  <mat-sidenav
    #sidenav
    class="h-screen border-none shadow"
    [mode]="mode"
    [opened]="openSidenav">
    <app-sidebar-side (clickItem)="onClickItem()"></app-sidebar-side>
  </mat-sidenav>

  <mat-sidenav-content class="bg-gray-100">
    <app-header-side (toggleSidenav)="toggleSidenav()"></app-header-side>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
