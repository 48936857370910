import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router } from '@angular/router';
import { AdminLayoutComponent } from '@layout/admin-layout/admin-layout.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalHttpErrorHandler } from '@shared/custom-error-handler/global-http-error.handler';
import { APIInterceptor } from '@shared/interceptors/api.interceptor';
import { ConnectionLostInterceptor } from '@shared/interceptors/connection-lost.interceptor';
import { InternatServerErrorInterceptor } from '@shared/interceptors/internal-server-error.interceptor';
import { JWTInterceptor } from '@shared/interceptors/jwt.interceptor';
import {
  API_HTTP_INTERCEPTORS,
  ApiHttpClient,
} from '@shared/services/api-http-client.service';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { SharedModule } from '@shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent],
  imports: [
    TranslateModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    SharedModule,
    MatSidenavModule,
    FileUploadModule,
    MatDatepickerModule,
    AppRoutingModule,
  ],
  providers: [
    ApiHttpClient,
    { provide: API_HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    {
      provide: API_HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      deps: [JwtAuthService, Router],
      multi: true,
    },
    {
      provide: API_HTTP_INTERCEPTORS,
      useClass: InternatServerErrorInterceptor,
      deps: [MatSnackBar, TranslateService],
      multi: true,
    },
    {
      provide: API_HTTP_INTERCEPTORS,
      useClass: ConnectionLostInterceptor,
      deps: [MatSnackBar, TranslateService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalHttpErrorHandler,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
