import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    const $body = document.querySelector('body');
    if ($body) {
      $body.innerHTML = `<h6 style="color: red;">${err.message}</h6>`;
    }
  });
