import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternatServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly snack: MatSnackBar,
    private readonly translate: TranslateService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status >= 500) {
          this.snack.open(
            this.translate.instant(
              'SNACKBAR.DETAILED_MESSAGES.INTERNAL_SERVER_ERROR'
            ),
            this.translate.instant('SNACKBAR.ACTIONS.CLOSE'),
            {
              duration: 5000,
            }
          );
        }
        return throwError(() => error);
      })
    );
  }
}
