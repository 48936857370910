import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalHttpErrorHandler implements ErrorHandler {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService
  ) {}

  public handleError(error: unknown): void {
    if (
      error instanceof HttpErrorResponse &&
      error.status >= 400 &&
      error.status < 500
    ) {
      this.snackBar.open(
        this.translate.instant('SNACKBAR.DETAILED_MESSAGES.REQUEST_ERROR'),
        this.translate.instant('SNACKBAR.ACTIONS.CLOSE')
      );

      return;
    }

    throw error;
  }
}
