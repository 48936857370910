import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  showToggle!: string;
  mode!: MatDrawerMode;
  openSidenav!: boolean;
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  private subscription!: Subscription;

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(private jwtAuth: JwtAuthService) {
    this.jwtAuth.checkTokenIsValid().subscribe();
  }

  ngOnInit() {
    this.subscription = this.getScreenWidth().subscribe(width => {
      if (width < 959) {
        this.showToggle = 'show';
        this.mode = 'over';
        this.openSidenav = false;
      } else if (width > 959) {
        this.showToggle = 'hide';
        this.mode = 'side';
        this.openSidenav = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(innerWidth: number) {
    this.screenWidth$.next(innerWidth);
  }

  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  onClickItem() {
    if (this.mode === 'over' && this.sidenav.opened) {
      this.sidenav.close();
    }
  }
}
