import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '@layout/admin-layout/admin-layout.component';
import { authGuard } from '@shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'sessions',
    loadChildren: () =>
      import('@modules/session/session.module').then(m => m.SessionModule),
    data: { title: 'SESSIONS' },
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/default/default.module').then(m => m.DefaultModule),
        data: { title: 'DASHBOARD' },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@modules/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'PROFILE' },
      },
      {
        path: 'solicitudes',
        loadChildren: () =>
          import('@modules/solicitudes/solicitudes.module').then(
            m => m.SolicitudesModule
          ),
        data: { title: 'REQUESTS' },
      },
      {
        path: 'ofertas',
        loadChildren: () =>
          import('@modules/ofertas/ofertas.module').then(m => m.OfertasModule),
        data: { title: 'QUOTES' },
      },
      {
        path: 'pedidos',
        loadChildren: () =>
          import('@modules/pedidos/pedidos.module').then(m => m.PedidosModule),
        data: { title: 'ORDERS' },
      },
      {
        path: 'usuarios',
        loadChildren: () =>
          import('@modules/usuarios/usuarios.module').then(
            m => m.UsuariosModule
          ),
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'sessions/signin4',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
