import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    private readonly jwtAuth: JwtAuthService,
    private readonly router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwt = this.jwtAuth.getJwtToken();
    if (jwt) {
      req = req.clone({
        setHeaders: {
          Authorization: `${jwt}`,
        },
      });
    }
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status !== 401) {
          return throwError(() => error);
        }
        this.jwtAuth.setUserAndToken(null, null, false);

        this.router.navigate(['/auth']);
        return throwError(() => error);
      })
    );
  }
}
